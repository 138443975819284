<template>
    <div>
        <Horizontal ref="Horizontal" :redPacketTypeList="redPacketTypeList" :currentRedPack="currentRedPack"
            :otherTaskList="otherTaskList" :redPacketList="redPacketList" :currentIndex="currentIndex"
            @choiceRedType="choiceRedType" @getFGRedPacket="getFGRedPacket" @getRechargeRedPacket="getRechargeRedPacket"
            @openInvitationCodeForm="openInvitationCodeForm" @openSignRedPacketDialog="openSignRedPacketDialog"
            @goInvite="goInvite" v-if="$store.getters.currentPageVersion === 'Horizontal'"></Horizontal>

        <Vertical ref="Vertical" :redPacketTypeList="redPacketTypeList" :currentRedPack="currentRedPack"
            :otherTaskList="otherTaskList" :redPacketList="redPacketList" :currentIndex="currentIndex"
            @choiceRedType="choiceRedType" @getFGRedPacket="getFGRedPacket" @getRechargeRedPacket="getRechargeRedPacket"
            @openInvitationCodeForm="openInvitationCodeForm" @openSignRedPacketDialog="openSignRedPacketDialog"
            @goInvite="goInvite" v-else></Vertical>

        <DialogWhenGetRedPacket @closeRedGetDialog="closeRedGetDialog" 
            :dialogOpenType="dialogOpenType"
            :gotMoneyOfRedPacket="gotMoneyOfRedPacket" 
            :isShowRedDialogInfo="isShowRedDialogInfo"
            @backImgLoadDone="backImgLoadDone" 
            v-if="isOpenDialogOfRedPacket"></DialogWhenGetRedPacket>

        <InvitationCodeDialog @closeInvitationCodeForm="closeInvitationCodeForm"
            @submitInvitationCode="submitInvitationCode" v-if="isShowInvitationCodeDialog"></InvitationCodeDialog>
    </div>
</template>

<script>
    import Horizontal from '@/components/Index/IndexRedPacket/Horizontal/Index.vue'
    import Vertical from '@/components/Index/IndexRedPacket/Vertical/Index.vue'

    import DialogWhenGetRedPacket from '@/components/Index/IndexRedPacket/RedPacketOfDialog/Index.vue' //领取红包时的交互弹窗画面
    import InvitationCodeDialog from '@/components/Index/IndexRedPacket/InvitationCodeDialog/Index.vue'

    //其他任务红包icon
    import otherCode from '@/assets/index/other_code.svg'
    import otherInvite from '@/assets/index/other_invite.svg'

    export default {
        components: {
            Horizontal,
            Vertical,
            DialogWhenGetRedPacket,
            InvitationCodeDialog
        },
        data() {
            return {
                //任务红包数据
                redPacketTypeList: [],
                currentRedPack: null,
                currentIndex: 0,
                //其他任务列表
                otherTaskList: [{
                        title: '邀请好友',
                        icon: otherInvite,
                        remark: '得大额红包，好友充值再得红包'
                    },
                    {
                        title: '填写邀请码',
                        icon: otherCode,
                        remark: '注册24小时内填写有效'
                    }
                ],
                //红包列表
                redPacketList: [],
                //红包领取
                isOpenDialogOfRedPacket: false, //是否显示领取成功弹窗
                dialogOpenType: 'SIGN_RED_PACKET', //领取成功弹窗显示的红包类型
                gotMoneyOfRedPacket: 0, //领取成功弹窗显示的金额
                statusOfReceive : false,//是否正在领取红包，防止反复领取
                isShowRedDialogInfo:false,
                
                //邀请码
                isShowInvitationCodeDialog: false,
                isSubmitingCode:false//当前是否正在执行提交邀请码后的流程,防止重复提交
            }
        },
        created() {
            this.initCurrentPageInfo()
        },
        methods: {
            //获取当前页面数据
            initCurrentPageInfo() {
                console.log("初始化首页")
                this.initForRedPacketMod()
            },
            //初始化红包模块
            initForRedPacketMod() {
                console.log('初始化红包模块')
                const initData = {
                    that: this,
                    roleId: this.$store.state.roleId !== '--' ? this.$store.state.roleId : ''
                }
                this.$store.dispatch('initForRedPacketMod', initData)
            },
            choiceRedType(i) {
                const {
                    type,
                    index
                } = i
                console.log(index)
                this.redPacketList = []
                this.currentRedPack = type
                this.currentIndex = index || 0
                console.log(type)
                switch (type) {
                    case 'levelPower':
                        this.getRedListForFightingPower()
                        break;
                    case 'recharge':
                        this.getRedListForRecharge()
                        break;
                    default:
                        this.getRedListForFightingPower()
                        break
                }
            },
            //获取战力和等级红包
            getRedListForFightingPower() {
                let requestData = {
                    role_id: this.$store.state.roleId,
                    token: this.$store.state.token
                }
                this.$api.redPacket.fightingCapacityRedList(requestData).then(res => {
                    if (res.data.code === 200) {
                        console.log(this.redPacketList)
                        let redPacketList = res.data.data
                        if(redPacketList.length>0&&redPacketList[0].hasOwnProperty('reward_type')) {
                            if(redPacketList[0].reward_type === 2) {
                                //等级红包
                                this.redPacketTypeList[0].title = '等级红包'
                            }else{
                                //战力红包
                                this.redPacketTypeList[0].title = '战力红包'
                            }
                            if(this.currentRedPack === 'levelPower') {
                                this.redPacketList = redPacketList
                            }
                        }else {
                            if(this.currentRedPack === 'levelPower') {
                                this.redPacketTypeList.shift()
                            }
                            console.log(this.redPacketTypeList)
                        }
                        
                    }
                })
            },
            //获取充值红包列表
            getRedListForRecharge() {
                let requestData = {
                    role_id: this.$store.state.roleId,
                    token: this.$store.state.token
                }
                this.$api.redPacket.rechargeRedList(requestData).then(res => {
                    if (res.data.code === 200) {
                        //获取等级战力红包标签
                        this.getRedListForFightingPower()
                        //
                        this.redPacketList = res.data.data
                    }
                })
            },
            //领取限流器
            restrictionsOfReceive () {
                if(this.statusOfReceive === true) {
                    //当前点击太频繁
                    this.$Toast({
                    	message: '操作频繁，请稍后再试',
                    })
                    return false
                }
                this.statusOfReceive = true
                setTimeout(()=> {
                    this.statusOfReceive = false
                },2000)
                return true
            },
            // 领取战力等级红包
            getFGRedPacket(redPacketInfo) {
                if(!this.restrictionsOfReceive()) {
                    return
                }
                if(redPacketInfo.get_status == 4) {
                    this.$Toast({
                    	message: '未达标',
                    })
                    return
                }
                let requestData = {
                    role_id: this.$store.state.roleId,
                    token: this.$store.state.token,
                    reward_id: redPacketInfo.id
                }
                this.$api.redPacket.getFGRedPacket(requestData).then(res => {
                    if (res.data.code === 200) {
                        this.openRedPacketDialog('FG_RECHARGE_RED_PACKET', redPacketInfo.reward_params
                            .reward_money)
                    }
                })
            },
            //领取充值红包
            getRechargeRedPacket(redPacketInfo) {
                if(!this.restrictionsOfReceive()) {
                    return
                }
                let requestData = {
                    role_id: this.$store.state.roleId,
                    token: this.$store.state.token,
                    reward_id: redPacketInfo.id
                }
                this.$api.redPacket.getRechargeRedPacket(requestData).then(res => {
                    if (res.data.code === 200) {
                        //弹出红包弹窗
                        this.openRedPacketDialog('FG_RECHARGE_RED_PACKET', redPacketInfo.reward_params
                            .reward_money)
                    }
                })
            },
            //打开签到红包弹窗
            openSignRedPacketDialog(e) {
                console.log('打开签到红包弹窗')
                console.log(e)
                this.openRedPacketDialog('SIGN_RED_PACKET', e)
            },
            //领取红包成功，打开弹窗
            openRedPacketDialog(type, money) {
                this.isOpenDialogOfRedPacket = true
                this.dialogOpenType = type
                this.gotMoneyOfRedPacket = Number(money)
            },
            //红包背景图加载完成
            backImgLoadDone() {
                console.log('图片加载完成')
                this.isShowRedDialogInfo = true
            },
            //关闭红包领取成功的弹窗
            closeRedGetDialog() {
                this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                overlay:false,
                message: '',
            });
                //更新余额
                setTimeout(()=> {
                    this.$Toast.clear();
                    this.isOpenDialogOfRedPacket = false
                    this.isShowRedDialogInfo = false
                    this.initForRedPacketMod()
                },1500)
            },
            //打开邀请码输入弹窗
            openInvitationCodeForm() {
                this.isShowInvitationCodeDialog = true
            },
            closeInvitationCodeForm() {
                this.isShowInvitationCodeDialog = false
            },
            submitInvitationCode(code) {
                if(this.isSubmitingCode) {
                    this.$Toast({
                        message: '操作频繁，请3s后再试',
                    })
                    return
                }
                this.isSubmitingCode = true
                this.isSubmitingCode = true
                if (!code || code.length < 1) {
                    this.$Toast({
                        message: '邀请码不能为空',
                    })
                    return
                }
                console.log('code:' + code)
                let requestData = {
                    role_id: this.$store.state.roleId,
                    token: this.$store.state.token,
                    invite_code: code,
                }
                this.$api.redPacket.bindInvitationCode(requestData).then(res => {
                    if (res.data.code === 200) {
                        this.$Toast({
                            message: '绑定成功！'
                        });
                        setTimeout(()=> {
                            this.closeInvitationCodeForm()
                            //更新被邀请状态
                            this.initForRedPacketMod()
                        },1500)
                    }else {
                        setTimeout(()=> {
                            this.closeInvitationCodeForm()
                        },1500)
                        
                    }
                    setTimeout(()=> {
                       this.isSubmitingCode = false
                    },3000)
                    
                })
            },
            goInvite() {
                this.$router.push({
                    name: 'InviteIndex',
                    params: {}
                })
            }
        }
    }
</script>

<style>
</style>