<template>
    <div class="form-box">
        <div class="title-box">
            <div></div>
            <div class="title-text">填写邀请码</div>
            <img src="@/assets/index/close_dialog.svg" class="close-icon" @click="closeInvitationCodeForm">
        </div>
        <div class="input-box">
            <input ref="icInput" v-model="invitationCode"/>
        </div>
        <div class="btn-box">
            <div class="btn" @click="submitInvitationCode">
                确定
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            
        },
        data() {
            return {
                invitationCode:""
            }
        },
        mounted() {
          this.$nextTick(()=> {
              this.$refs.icInput.focus()
          })  
        },
        methods: {
            closeInvitationCodeForm() {
                this.$emit('closeInvitationCodeForm')
            },
            submitInvitationCode() {
                this.$emit('submitInvitationCode', this.invitationCode)
            }
        }
    }
</script>

<style scoped>
   .form-box {
       width: 175px;
       height: 124px;
       background: #FFFFFF;
       border-radius: 8.75px;
       padding: 13.75px 16.25px 17px 16.25px;
   }
   .title-box {
       width: 100%;
       display: flex;
       justify-content: space-between;
       align-items: center;
   }
   .title-text {
       flex: 1;
       font-size: 11.25px;
       font-family: PingFang SC-Regular, PingFang SC;
       font-weight: 400;
       color: #000000;
       text-align: center;
   }
   .close-icon {
       width: 7px;
   }
   .input-box {
       margin-top: 13.75px;
       padding: 0 9px;
       font-size: 8.75px;
       border-radius: 5px;
       background-color: #F4F4F4;
       color: #333333;
   }
   .input-box>input {
       border: none;
       width: 100%;
       height: 23.75px;
       background-color: #F4F4F4;
   }
   .btn-box {
       width: 100%;
       padding-top: 16.25px;
   }
   .btn {
       width: 100%;
       height: 23px;
       background: #13C6CE;
       border-radius: 12px;
       font-size: 10px;
       font-family: PingFang SC-Regular, PingFang SC;
       font-weight: 400;
       color: #FFFFFF;
       display: flex;
       align-items: center;
       justify-content: center;
   }
</style>