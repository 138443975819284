<template>
    <div class="index-page">
       <RoleInfo></RoleInfo>
       <Exchange class="box-padding"></Exchange>
       <div ref="indexScrollBox"  class="bottom-scroll-box" @scroll="scroll" v-if="$store.getters.redModIsNotNull">
           <SignRedPacket 
           @openSignRedPacketDialog="openSignRedPacketDialog"
           class="box-padding sign-red-packet"
           v-if="$store.state.switchStatusOfRedPacket.RedPacketOfSign === 1"
           ref="signRedPacket"
           ></SignRedPacket>
           <TaskRedPacket class="box-padding"
           :redPacketTypeList="redPacketTypeList" 
           :currentRedPack="currentRedPack"
           :currentIndex="currentIndex"
           :isToTop="isToTop"
           :otherTaskList="otherTaskList"
           :redPacketList="redPacketList"
           @choiceRedType="choiceRedType"
           @getFGRedPacket="getFGRedPacket"
           @openInvitationCodeForm="openInvitationCodeForm"
           @goInvite="goInvite"
           @getRechargeRedPacket="getRechargeRedPacket"
           v-if="$store.getters.isShowOtherRedPacket || $store.getters.isShowRechargeRedPacket || $store.getters.isShowFightingPowerRedPacket"
           ></TaskRedPacket>
       </div>
       <div class="bottom-scroll-box blank-list" v-else>
           <img src="@/assets/blank/blank_activity.svg" alt="">
           <div>暂无更多活动</div>
       </div>
    </div>
</template>

<script>
    import RoleInfo from '@/components/Index/IndexRedPacket/Vertical/RoleInfo.vue'
    import Exchange from '@/components/Index/IndexRedPacket/Exchange/Index.vue'
    import SignRedPacket from '@/components/Index/IndexRedPacket/SignRedPacket/Index.vue'
    import TaskRedPacket from '@/components/Index/IndexRedPacket/Vertical/TaskRedPacket/Index.vue'
    
    
    
    export default {
        components: {
            RoleInfo,
            Exchange,
            SignRedPacket,
            TaskRedPacket
        },
        props:{
            redPacketTypeList:Array,
            currentRedPack:String,
            currentIndex:Number,
            otherTaskList:Array,
            redPacketList:Array,
        },
        data() {
            return {
                isToTop:false,//红包领取列表模块是否滚动到吸顶位置
                ToTopDistance:0
            }
        },
        created() {
          if(this.$store.state.switchStatusOfRedPacket.RedPacketOfSign !== 1 || !this.$store.getters.signRedPacketStatus) {
              this.isToTop = true
          }  
          console.log('this.isToTop:'+ this.isToTop)
          console.log('this.isToTop:'+ this.$store.getters.signRedPacketStatus)
        },
        mounted() {
            
        },
        methods: {
            choiceRedType(i) {
                this.$emit('choiceRedType', i);
            },
            scroll(e) {
                // console.log(this.$refs.indexScrollBox.scrollTop);
                if(this.$store.state.switchStatusOfRedPacket.RedPacketOfSign !== 1 || !this.$store.getters.signRedPacketStatus) {
                   return
                } 
                if(!this.ToTopDistance || this.ToTopDistance<1) {
                    let element = this.$el.querySelector('.sign-red-packet')
                    this.ToTopDistance  = element.offsetHeight-10
                    console.log(this.ToTopDistance)
                }
                
                
                if(this.$refs.indexScrollBox.scrollTop>this.ToTopDistance) {
                    //吸顶
                    this.isToTop = true
                    
                }else {
                    this.isToTop = false
                }
            },
            getFGRedPacket(redPacketInfo) {
                this.$emit('getFGRedPacket', redPacketInfo)
            },
            getRechargeRedPacket(redPacketInfo) {
                this.$emit('getRechargeRedPacket', redPacketInfo)
            },
            getRedPacketOfSign() {
                this.$emit('getRedPacketOfSign')
            },
            openInvitationCodeForm() {
                this.$emit('openInvitationCodeForm')
            },
            openSignRedPacketDialog(money) {
                this.$emit('openSignRedPacketDialog', money)
            },
            goInvite() {
                this.$emit('goInvite')
            }
        }
    }
</script>

<style scoped>
    .index-page {
        /* padding: 22px 15px; */
        height: 100%;
        padding-top: 7px;
        display: flex;
        flex-direction: column;
    }
    .box-padding {
        padding-top: 10px;
        
    }
    .bottom-scroll-box {
        flex:1;
        display: flex;
        flex-direction: column;
        overflow: scroll;
    }
    
    
    .blank-list {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .blank-list>img {
        width: 100%;
    }
    .blank-list>div {
        padding-top: 11;
        font-size: 8px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
</style>
