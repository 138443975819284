<template>
    <div class="red-list">
        <div class="red-item" v-for="(item, index) in redPacketList" :key="index">
            <img src="@/assets/index/recharge_list.svg" class="red-icon">
            <div class="red-pack-info">
                <div class="red-title">
                    累计充值{{(Number(item.reward_params.recharge_money)/100).toFixed(2)}}元得{{(Number(item.reward_params.reward_money)/100).toFixed(2) }}元
                </div>
            </div>
            <div class="get-btn-box">
                <div class="get-btn get-ed"  v-if="item.get_status === 1">
                    已领取
                </div>
                <div class="get-btn" @click="getRedPacket(item)" v-else>
                    领取
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        props:{
            redPacketList:Array
        },
        data() {
            return {
                getRedPacket(redPacketInfo) {
                    this.$emit('getRedPacket', redPacketInfo)
                }
            }
        },
        methods: {
           
        },
        }
</script>

<style scoped>
    .red-item {
        width: 100%;
        padding: 10px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F4F4F4;
    }
    .red-title {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
    .red-icon {
        width: 28px;
    }
    .red-pack-info {
        padding-left: 10px;
        max-width: 200px;
    }
    .get-btn-box {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .get-btn {
        width: 60px;
        height: 26px;
        background: rgba(19,198,206,0.22);
        border-radius: 16px 16px 16px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #13C6CE;
    }
    .get-ed {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        background: #D8D8D8;
    }
</style>