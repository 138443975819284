<template>
    <div class="form-box">
        <div class="title-box">
            <div></div>
            <div class="title-text">填写邀请码</div>
            <img src="@/assets/index/close_dialog.svg" class="close-icon" @click="closeInvitationCodeForm">
        </div>
        <div class="input-box">
            <input ref="icInput" v-model="invitationCode"/>
        </div>
        <div class="btn-box">
            <div class="btn" @click="submitInvitationCode">
                确定
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            
        },
        data() {
            return {
                invitationCode:""
            }
        },
        mounted() {
          this.$nextTick(()=> {
              this.$refs.icInput.focus()
          })  
        },
        methods: {
            closeInvitationCodeForm() {
                this.$emit('closeInvitationCodeForm')
            },
            submitInvitationCode() {
                this.$emit('submitInvitationCode', this.invitationCode)
            }
        }
    }
</script>

<style scoped>
   .form-box {
       width: 280px;
       height: 198px;
       background: #FFFFFF;
       border-radius: 14px 14px 14px 14px;
       padding: 22px 26px 27px 26px;
   }
   .title-box {
       width: 100%;
       display: flex;
       justify-content: space-between;
       align-items: center;
   }
   .title-text {
       flex: 1;
       font-size: 18px;
       font-family: PingFang SC-Regular, PingFang SC;
       font-weight: 400;
       color: #000000;
       text-align: center;
   }
   .close-icon {
       width: 11px;
   }
   .input-box {
       margin-top: 22px;
       padding: 0 15px;
       font-size: 14px;
       border-radius: 8px 8px 8px 8px;
       background-color: #F4F4F4;
       color: #333333;
   }
   .input-box>input {
       border: none;
       width: 100%;
       height: 38px;
       background-color: #F4F4F4;
   }
   .btn-box {
       width: 100%;
       padding-top: 26px;
   }
   .btn {
       width: 100%;
       height: 38px;
       background: #13C6CE;
       border-radius: 19px 19px 19px 19px;
       font-size: 16px;
       font-family: PingFang SC-Regular, PingFang SC;
       font-weight: 400;
       color: #FFFFFF;
       display: flex;
       align-items: center;
       justify-content: center;
   }
</style>