<template>
    <div class="index-page">
       <div ref="indexScrollBox" class="bottom-scroll-box" v-if="$store.getters.isShowOtherRedPacket || $store.getters.isShowRechargeRedPacket || $store.getters.isShowFightingPowerRedPacket">
           <TaskRedPacket class="box-padding" 
           :redPacketTypeList="redPacketTypeList" 
           :currentRedPack="currentRedPack"
           :currentIndex="currentIndex"
           :otherTaskList="otherTaskList"
           :redPacketList="redPacketList"
           @choiceRedType="choiceRedType"
           @getFGRedPacket="getFGRedPacket"
           @openInvitationCodeForm="openInvitationCodeForm"
           @goInvite="goInvite"
           @getRechargeRedPacket="getRechargeRedPacket"
           ></TaskRedPacket>
       </div>
       <div class="bottom-scroll-box blank-list" v-else>
           <img src="@/assets/blank/blank_activity.svg" alt="">
           <div>暂无更多活动</div>
       </div>
    </div>
</template>

<script>
    import TaskRedPacket from '@/components/Index/IndexRedPacket/Horizontal/TaskRedPacket/Index.vue'
    
    
    
    export default {
        components: {
            TaskRedPacket
        },
        props:{
            redPacketTypeList:Array,
            currentRedPack:String,
            currentIndex:Number,
            otherTaskList:Array,
            redPacketList:Array,
        },
        data() {
            return {
            }
        },
        methods: {
            choiceRedType(i) {
                console.log(i)
                this.$emit('choiceRedType', i)
            },
            getFGRedPacket(redPacketInfo) {
                this.$emit('getFGRedPacket', redPacketInfo)
            },
            getRechargeRedPacket(redPacketInfo) {
                this.$emit('getRechargeRedPacket', redPacketInfo)
            },
            getRedPacketOfSign() {
                this.$emit('getRedPacketOfSign')
            },
            openInvitationCodeForm() {
                this.$emit('openInvitationCodeForm')
            },
            openSignRedPacketDialog(money) {
                this.$emit('openSignRedPacketDialog', money)
            },
            goInvite() {
                this.$emit('goInvite')
            }
        }
    }
</script>

<style scoped>
    .index-page {
        /* padding: 22px 15px; */
        height: 100%;
    }
    .bottom-scroll-box {
        height: 100%;
    }
    .blank-list {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .blank-list>img {
        width: 100%;
    }
    .blank-list>div {
        padding-top: 11;
        font-size: 8px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
</style>
