<template>
    <div class="red-list">
        <div class="red-item" v-for="(item, index) in redPacketList" :key="index">
            <img src="@/assets/index/fighting.svg" class="red-icon">
            <div class="red-pack-info">
                <div class="red-title">
                    {{Number(item.reward_params.days)&&Number(item.reward_params.days)>0?`${item.reward_params.days}天`:''}}{{item.reward_params.reward_type==3?`达到${item.reward_params.fighting_capacity}战力`:''}}{{item.reward_params.reward_type==2?`达到${item.reward_params.level}级`:''}}{{item.reward_params.show_max&&item.reward_params.show_max>0?`最高可得`:'得'}}{{item.reward_params.show_max&&item.reward_params.show_max>0?`${Number(item.reward_params.show_max/100)}元`:`${Number(item.reward_params.reward_money/100)}元`}}
                </div>
                <div class="red-count-one-box" v-if="item.reward_params.unlimit == 1">
                    <span class="red-count-one">
                        红包不限量
                    </span>
                </div>
                <div class="red-count-tow-box" v-else>
                    <div class="red-count-tow" v-if="item.reward_params.hasOwnProperty('total_reward_nums')">
                        <div class="progress-box">
                            <div class="progress" 
                            :style="{width:((Number(item.reward_params.reward_nums)/Number(item.reward_params.total_reward_nums))*100)+ '%'}"
                            ></div>
                        </div>
                        <div class="progress-count" >
                            剩余{{(Number(item.reward_params.reward_nums)/Number(item.reward_params.total_reward_nums)*100).toFixed(0)}}%
                        </div>
                    </div>
                    
                    <div class="red-count-tow" v-else>
                        <div class="progress-box">
                            <div class="progress"
                            :style="{width:(Number(item.reward_params.get_nums)/Number(item.reward_params.reward_nums)*100)+ '%'}"></div>
                        </div>
                        <div class="progress-count" >
                            剩余{{(Number(item.reward_params.get_nums)/Number(item.reward_params.reward_nums)*100).toFixed(0)}}%
                        </div>
                    </div>
                </div>
            </div>
            <!-- 不限量 -->
            <div class="get-btn-box" v-if="item.reward_params.unlimit ==1">
                <div class="get-btn get-ed" v-if="item.get_status == 1">
                    已领取
                </div>
                <div class="get-btn " @click="getRedPacket(item)" v-else>
                    领取
                </div>
            </div>
            <!-- 限量 -->
            <div class="get-btn-box" v-else>
                <div class="get-btn get-ed" v-if="item.get_status == 1">
                    已领取
                </div>
                <div v-else>
                    <div class="get-btn" v-if="item.reward_params.reward_nums>0" @click="getRedPacket(item)">领取</div>
                    <div class="get-btn get-ed" v-else>已领完</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        props:{
            redPacketList:Array
        },
        data() {
            return {
                testone:0,
                testtne:30
            }
        },
        methods: {
           getRedPacket(redPacketInfo) {
               redPacketInfo.type
               this.$emit('getRedPacket', redPacketInfo)
           }
        },
        }
</script>

<style scoped>
    .red-item {
        width: 100%;
        padding: 10px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F4F4F4;
    }
    .red-title {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
    .red-icon {
        width: 28px;
    }
    .red-pack-info {
        padding-left: 10px;
        max-width: 200px;
    }
    .red-count-one-box {
        padding-top: 6px;
    }
    .red-count-one {
        border: 1px solid #F84935;
        font-size: 11px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #F84935;
        padding: 0 3px;
    }
    .red-count-tow {
        display: flex;
        align-items: center;
        
    }
    .progress-box {
        width: 120px;
        height: 6px;
        background: #FFFFFF;
        box-shadow: 0px 0px 2px 1px #B3E2E5;
        border-radius: 7px 7px 7px 7px;
    }
    .progress {
        width: 0;
        height: 6px;
        background: linear-gradient(270deg, #76E9DF 0%, #56C8E3 100%);
        border-radius: 7px 7px 7px 7px;
    }
    .progress-count {
        padding-left: 10px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
    }
    .get-btn-box {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .get-btn {
        width: 60px;
        height: 26px;
        background: rgba(19,198,206,0.22);
        border-radius: 16px 16px 16px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #13C6CE;
    }
    .get-ed {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        background: #D8D8D8;
    }
</style>