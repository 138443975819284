<template>
    <div class="dialog-red-get-box">
        <transition name="redPacket" appear>
            <Horizontal
            ref="Horizontal"
            @submitInvitationCode="submitInvitationCode"
            @closeInvitationCodeForm="closeInvitationCodeForm" 
            v-if="$store.getters.currentPageVersion === 'Horizontal'">
            </Horizontal>
            <Vertical 
            ref="Vertical"
            @submitInvitationCode="submitInvitationCode"
            @closeInvitationCodeForm="closeInvitationCodeForm" 
            v-else></Vertical>
        </transition>
    </div>
</template>

<script>
    import Horizontal from '@/components/Index/IndexRedPacket/InvitationCodeDialog/Horizontal.vue'
    import Vertical from '@/components/Index/IndexRedPacket/InvitationCodeDialog/Vertical.vue'
    export default {
        components: {
            Horizontal,
            Vertical
        },
        props: {

        },
        data() {
            return {}
        },
        methods: {
            closeInvitationCodeForm() {
                this.$emit('closeInvitationCodeForm')
            },
            submitInvitationCode(code) {
                this.$emit('submitInvitationCode', code)
            }
        }
    }
</script>

<style scoped>
    .redPacket-enter,
    .redPacket-leave-to,
    .redPacket-enter {
        opacity: 0;
        transform: scale(0);
    }

    .redPacket-enter-to,
    .redPacket-leave {
        opacity: 1;
        transform: scale(1);
    }

    .redPacket-enter-active,
    .redPacket-leave-active {
        transition: 0.3s;
    }

    .dialog-red-get-box {
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>