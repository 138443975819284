<template>
    <div class="task-red-packet-box">
        <div class="type-box">
            <div class="type-icon-list-box">
                <div class="type-icon-list">
                    <div class="type-icon-item" v-for="(item, index) in redPacketTypeList" :key="index"
                        @click="choiceRedType(item.type, index)">
                        <div class="type-icon-box">
                            <img :src="item.type_icon" class="type-icon">
                            <img :src="item.type_icon_s" class="type-icon-s" v-if="currentRedPack === index">
                        </div>

                        <div class="type-title-box">
                            <div class="title-text" :class="currentRedPack === index?'title-text-s':'title-text'">
                                {{item.title}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="type-bottom-line" :style="{left: currentIndex*34  + '%'}"></div>
            </div>
        </div>
        <div class="redList-box">
            <FightingAndGrade :redPacketList="redPacketList" @getRedPacket="getFGRedPacket"
                v-if="currentRedPack==='levelPower'"></FightingAndGrade>
            <Recharge :redPacketList="redPacketList" @getRedPacket="getRechargeRedPacket"
                v-else-if="currentRedPack==='recharge'"></Recharge>
            <OtherTask :otherTaskList="otherTaskList" @openInvitationCodeForm="openInvitationCodeForm"
                @goInvite="goInvite" v-else-if="currentRedPack === 'other'"></OtherTask>
        </div>
    </div>
</template>

<script>
    import FightingAndGrade from '@/components/Index/IndexRedPacket/Horizontal/TaskRedPacket/FightingAndGrade.vue'
    import Recharge from '@/components/Index/IndexRedPacket/Horizontal/TaskRedPacket/Recharge.vue'
    import OtherTask from '@/components/Index/IndexRedPacket/Horizontal/TaskRedPacket/OtherTask.vue'

    export default {
        components: {
            FightingAndGrade,
            Recharge,
            OtherTask
        },
        props: {
            redPacketTypeList: Array,
            currentRedPack: String,
            currentIndex: Number,
            otherTaskList: Array,
            redPacketList: Array
        },
        data() {
            return {

            }
        },
        methods: {
            choiceRedType(type, index) {
                this.$emit('choiceRedType', {
                    type,
                    index
                })
            },
            getFGRedPacket(redPacketInfo) {
                this.$emit('getFGRedPacket', redPacketInfo)
            },
            getRechargeRedPacket(redPacketInfo) {
                this.$emit('getRechargeRedPacket', redPacketInfo)
            },
            openInvitationCodeForm() {
                this.$emit('openInvitationCodeForm')
            },
            goInvite() {
                console.log('跳转')
                this.$emit('goInvite')
            }
        }
    }
</script>

<style scoped>
    .task-red-packet-box {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .type-icon-list-box {
        padding: 0 5px 5px 5px;
        border-bottom: 1px solid #CCCCCC;
        position: relative;
    }

    .type-icon-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .type-icon-list>div:last-child:nth-child(3n+2) {
        margin-right: calc((100% - 52px)/2);
    }

    .type-icon-box {
        position: relative;
    }

    .type-icon {
        width: 52px;
    }

    .type-icon-s {
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        width: 6.25px;
    }

    .type-title-box {
        padding-top: 6px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .title-text {
        font-size: 9px;
        height: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        transition: 0.2s;
    }

    .title-text-s {
        font-weight: 500;
        color: #333333;
        transition: 0.2s;
    }

    .type-bottom-line {
        width: 32%;
        background-color: #333333;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: -1px;
        transition: 0.3s;
    }

    /* 红包列表样式 */
    .redList-box {
        flex: 1;
        overflow: scroll;
    }
</style>