<template>
    <div class="red-list">
        <div class="red-item" v-for="(item, index) in otherTaskList" :key="index">
            <img :src="item.icon" class="red-icon">
            <div class="red-pack-info">
                <div class="red-title">
                    {{item.title}}
                </div>
                <div class="red-count-one-box">
                     {{item.remark}}
                </div>
            </div>
            <div class="get-btn-box">
                <div class="get-btn" v-if="item.title === '邀请好友'" @click="goInvite">
                    无上限
                </div>
                
                <div class="get-btn" v-if="item.title === '填写邀请码' && $store.state.userInviteeStatus === 1" @click="openInvitationCodeForm">
                    去填写
                </div>
                <div class="get-btn get-ed" v-if="item.title === '填写邀请码'&& $store.state.userInviteeStatus === 2">
                    已绑定
                </div>
                <!-- <div class="get-btn get-ed">
                    已领取
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        props:{
            otherTaskList:Array
        },
        data() {
            return {
                
            }
        },
        created() {
          console.log(this.$props.otherTaskList)  
        },
        methods: {
           openInvitationCodeForm() {
               this.$emit('openInvitationCodeForm')
           },
           goInvite() {
               this.$emit('goInvite')
           }
        },
        }
</script>

<style scoped>
    .red-item {
        width: 100%;
        padding: 10px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F4F4F4;
    }
    .red-icon {
        width: 28px;
    }
    .red-pack-info {
        padding-left: 10px;
        max-width: 200px;
    }
    .red-title {
        font-size: 15px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
    .red-count-one-box {
        padding-top: 6px;
        font-size: 11px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
    }
    .get-btn-box {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .get-btn {
        width: 60px;
        height: 26px;
        background: #13C6CE;
        border-radius: 16px 16px 16px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
    }
    .get-ed {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        background: #D8D8D8;
    }
</style>